function OurStory() {
  return (
    <div className="mt-20 p-4">
      <h1>Our Story</h1>
      <p>Learn about our story.</p>
    </div>
  );
}

export default OurStory;
