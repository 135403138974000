function Gallery() {
  return (
    <div className="mt-20 p-4">
      <h1>Gallery</h1>
      <p>Check out our gallery!</p>
    </div>
  );
}

export default Gallery;
