import clubhouseImage from '../Clubhouse_Image_Alpha.webp';

function StartPage() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={clubhouseImage} alt="Clubhouse" className="max-w-full max-h-full" />
      <h1 className="mt-8 text-4xl font-bold">Start Page</h1>
      <p className="mt-4 text-xl">Welcome to the start page!</p>
    </div>
  );
}

export default StartPage;
