import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function Map() {
  const mapRef = useRef(null);

  const addMarker = (lat, lng, popupText) => {
    const map = mapRef.current;
    if (map) {
      const marker = L.marker([lat, lng]).addTo(map);
      if (popupText) {
        marker.bindPopup(popupText).openPopup();
      }
    }
  };

  return (
    <div className="h-screen">
      <MapContainer center={[0, 0]} zoom={4} style={{ height: '100%', width: '100%' }} ref={mapRef}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
      <button onClick={() => addMarker(51.505, -0.09, 'Marker 1')}>Add Marker 1</button>
      <button onClick={() => addMarker(40.7128, -74.0060, 'Marker 2')}>Add Marker 2</button>
    </div>
  );
}

export default Map;
