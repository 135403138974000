import { useState, useEffect } from "react";
import {
  HashConnect,
  HashConnectConnectionState,
  SessionData,
} from "hashconnect";
import { LedgerId } from "@hashgraph/sdk";
  const appMetadata = {
    name: "WookzApp",
    description: "TEST WookzApp",
    icons: [],
    url: "https://wookzman.com",
  };
const useHashConnect = () => {
  const [hashconnect, setHashconnect] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initHashconnect = async () => {
    

      const hashconnectInstance = await new HashConnect(
        LedgerId.MAINNET,
        "https://wookzman.com",
        appMetadata,
        true
      );


      hashconnectInstance.pairingEvent.on((newPairing) => {
        console.log("New pairing event:", newPairing);
      });

      hashconnectInstance.connectionStatusChangeEvent.on((connectionStatus) => {
        console.log("Connection status changed:", connectionStatus);
      });

     

      //open pairing modal

      setHashconnect(hashconnectInstance);
      setInitialized(true);

    };

    initHashconnect();
  }, []);

  const connectWallet = async () => {
    if (!hashconnect) {
      console.error("HashConnect not initialized");
      return;
    }

    try {
          console.log("hashconnect", hashconnect);
       hashconnect.pairingEvent.on((newPairing) => {
         console.log("New pairing event:", newPairing);
       });

      hashconnect.connectionStatusChangeEvent.on((connectionStatus) => {
        console.log("Connection status changed:", connectionStatus);
      });
      await hashconnect.init(appMetadata, "mainnet", true);
 console.log("hashconnect", hashconnect);
        hashconnect.openPairingModal();

       
  
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };

  const checkWookz = async (walletData) => {
    if (
      walletData &&
      walletData.pairingData &&
      walletData.pairingData.accountIds
    ) {
      const accountId = walletData.pairingData.accountIds[0];
      const provider = hashconnect.getProvider("mainnet", accountId);
      const signer = hashconnect.getSigner(provider);

      try {
        const tokenIds = await signer.getTokens(accountId);
        const validWookz = tokenIds.filter((token) =>
          token.tokenId.startsWith("0.0.946799")
        ); // Replace with your Wookz token ID prefix
        console.log("Valid Wookz Tokens:", validWookz);
        // You can set additional state or perform actions based on valid tokens
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
    }
  };

  return {
    walletData,
    connectWallet,
    initialized,
  };
};

export default useHashConnect;
