function Shop() {
  return (
    <div className="mt-20 p-4">
      <h1>Shop</h1>
      <p>Visit our online shop!</p>
    </div>
  );
}

export default Shop;
